import { mapCountryToCountryCode } from "constants/countries";
import { PROVINCE_NAME_CODE_SEPARATOR } from "constants/provinces";

import { ChangeEvent, ComponentProps, ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { Collapse, CustomColor, FormControl, Grid, InputLabel } from "@portex-pro/ui-components";
import {
  useGetAddressSuggestionsQuery,
  useLazyGetAddressSuggestionsQuery,
} from "api/rest/address/getAddressSuggestionsApi";
import { Address, Maybe } from "api/types/generated-types";
import compact from "lodash/compact";
import debounce from "lodash/debounce";
import noop from "lodash/noop";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { AddressOption, LocationOptionTypeEnum } from "types/AddressOption";
import { Mode, ModeEnum } from "types/Mode";
import { normalizeAddressOption } from "utils/addresses/normalizeAddressOption";

import LocationPickerView from "./LocationPickerView";
import ManualLocationPickerView from "./ManualLocationPickerView";

export type LocationPickerProps = {
  autoFocus?: boolean;
  disabled?: boolean;
  highlight?: boolean;
  label?: string;
  mode?: Mode;
  onChange?: (value: Maybe<AddressOption>) => void;
  placeholder?: string;
  value?: Partial<Address>;
  startIconPallete?: CustomColor;
  exactAddressRequired?: boolean;
  toDoor?: boolean;
  variant?: "default" | "vertical";
};

const LocationPicker = ({
  autoFocus = false,
  disabled = false,
  highlight = false,
  label,
  mode = ModeEnum.FTL,
  onChange = noop,
  value,
  placeholder,
  startIconPallete = "green",
  toDoor = false,
  exactAddressRequired = false, // currently should only be true on shipments for pickup requests
  variant = "default",
}: LocationPickerProps): ReactElement => {
  const { t } = useTranslation("locationsV2");
  const [searchValue, _setSearchValue] = useState("");
  const [displayManualEntry, setDisplayManualEntry] = useState(false);
  const { data } = useGetAddressSuggestionsQuery({
    queryParams: { mode, search: searchValue, exactAddresses: exactAddressRequired, toDoor },
  });

  const [getLocationSuggestions] = useLazyGetAddressSuggestionsQuery();

  const setSearchValueDebounced = debounce((value: string) => _setSearchValue(value), 300);

  const currentValue = useMemo<Maybe<AddressOption>>(() => {
    if (!value) {
      return null;
    }

    return normalizeAddressOption(value);
  }, [value]);

  const clearSearchInput = () => setSearchValueDebounced("");

  const handleChange = (_event: ChangeEvent<{}>, value: AddressOption | null) => {
    if (!value) {
      return;
    }

    if (value.optionType === LocationOptionTypeEnum.Manual) {
      setDisplayManualEntry(true);
    }

    clearSearchInput();
    onChange(value);
  };

  const handleOnInputChange = useCallback(
    (_event: ChangeEvent<{}>, value: string, reason: string) => {
      if (reason === "input") {
        setSearchValueDebounced(value);
      }
    },
    [setSearchValueDebounced]
  );

  const handleManualAddressInputChange = debounce<ComponentProps<typeof ManualLocationPickerView>["onChange"]>(
    async ({ name, address_1, address_2, city, state, zip, country }) => {
      const [provinceName, provinceCode = ""] = state.split(PROVINCE_NAME_CODE_SEPARATOR); // province options can be found in PROVINCES_BY_COUNTRY and should be formatted [province_name - province_code]
      const manualAddressOption: AddressOption = {
        name: name.trim(),
        address_1: address_1.trim(),
        address_2: address_2.trim(),
        city: city.trim(),
        province_code: provinceCode,
        province_name: provinceName,
        zip,
        country_name: country,
        country_code: mapCountryToCountryCode[country as keyof typeof mapCountryToCountryCode],
        optionType: LocationOptionTypeEnum.Platform,
      };
      const addressParts = compact([
        manualAddressOption.address_1,
        manualAddressOption.city,
        manualAddressOption.province_code,
        manualAddressOption.zip,
      ]);
      const formattedAddress = addressParts.join(", ");
      let closestTimezoneMatch = undefined;

      if (formattedAddress) {
        try {
          const {
            data: { suggestions },
          } = await getLocationSuggestions({
            queryParams: { search: formattedAddress, mode, exactAddresses: exactAddressRequired, toDoor },
          }).unwrap();
          closestTimezoneMatch = suggestions.find(({ optionType }) => optionType === "GOOGLE_PLACES")?.iana_timezone;
        } catch (e) {
          Sentry.captureException(e);
        }
      }
      if (!closestTimezoneMatch) {
        try {
          const {
            data: { suggestions },
          } = await getLocationSuggestions({
            queryParams: { search: country, mode, exactAddresses: exactAddressRequired, toDoor },
          }).unwrap();
          closestTimezoneMatch = suggestions.find(({ optionType }) => optionType === "GOOGLE_PLACES")?.iana_timezone;
        } catch (e) {
          Sentry.captureException(e);
        }
      }

      manualAddressOption.iana_timezone = closestTimezoneMatch;
      onChange(manualAddressOption);
    },
    300
  );

  const showInternationalCountries = mode === "FCL" || mode === "AIR";

  useEffect(() => {
    // Since the debounce function uses a timer internally, it's a good practice to cancel any pending invocations
    // when the component unmounts to avoid potential issues.
    return () => {
      setSearchValueDebounced.cancel();
    };
  }, [setSearchValueDebounced]);

  if (variant === "vertical") {
    return (
      <div className="flex flex-col gap-3">
        <FormControl fullWidth={true}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={4}>
              <InputLabel>{t("formAddressSearchLabel")}</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <LocationPickerView
                autoFocus={autoFocus}
                disabled={disabled}
                highlight={highlight}
                onBlur={clearSearchInput}
                onChange={handleChange}
                onInputChange={handleOnInputChange}
                options={data?.data.suggestions || []}
                placeholder={placeholder}
                startIconPallete={startIconPallete}
                value={currentValue}
                mode={mode}
                toDoor={toDoor}
              />
            </Grid>
          </Grid>
        </FormControl>
        <ManualLocationPickerView
          value={currentValue}
          onChange={handleManualAddressInputChange}
          exactAddressRequired={exactAddressRequired}
          countries={showInternationalCountries ? "international" : "domestic"}
          variant={variant}
        />
      </div>
    );
  }

  return (
    <>
      <Collapse in={displayManualEntry}>
        <ManualLocationPickerView
          value={currentValue}
          onChange={handleManualAddressInputChange}
          exactAddressRequired={exactAddressRequired}
          countries={showInternationalCountries ? "international" : "domestic"}
          variant={variant}
          onClickCancel={() => {
            onChange(null);
            setDisplayManualEntry(false);
          }}
        />
      </Collapse>
      <Collapse in={!displayManualEntry}>
        <LocationPickerView
          autoFocus={autoFocus}
          disabled={disabled}
          highlight={highlight}
          label={label}
          onBlur={clearSearchInput}
          onChange={handleChange}
          onInputChange={handleOnInputChange}
          options={data?.data.suggestions || []}
          placeholder={placeholder}
          startIconPallete={startIconPallete}
          value={currentValue}
          mode={mode}
          toDoor={toDoor}
        />
      </Collapse>
    </>
  );
};

export default LocationPicker;
